import { ref, onMounted, getCurrentInstance, watch } from 'vue';
import { useStore } from 'vuex';
import ImageCarousel from './components/ImageCarousel.vue';
import CategorySwiper from './components/CategorySwiper.vue';
import Counter from './components/Counter/Counter.vue';
import JobOfferForm from './components/Forms/JobOfferForm/JobOfferForm.vue';
import ContactForm from './components/Forms/ContactForm/ContactForm.vue';
import Mosaic from "@scripts/vue/components/Mosaic.vue";
import Filters from "@scripts/vue/components/Filters.vue";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Autoplay, Thumbs, Grid, Mousewheel } from 'swiper/modules';

import { ElForm, ElFormItem, ElMenu, ElMenuItem, ElOption, ElSelect, ElSubMenu, ElDialog, ElCollapse, ElCollapseItem, ElTabs, ElTabPane, ElIcon, ElCheckbox, ElCheckboxGroup } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/form/style/css';
import 'element-plus/es/components/form-item/style/css';
import 'element-plus/es/components/menu/style/css';
import 'element-plus/es/components/menu-item/style/css';
import 'element-plus/es/components/option/style/css';
import 'element-plus/es/components/select/style/css';
import 'element-plus/es/components/sub-menu/style/css';
import 'element-plus/es/components/dialog/style/css';
import 'element-plus/es/components/collapse/style/css';
import 'element-plus/es/components/collapse-item/style/css';
import 'element-plus/es/components/tabs/style/css';
import 'element-plus/es/components/tab-pane/style/css';
import 'element-plus/es/components/icon/style/css';
import 'element-plus/es/components/checkbox/style/css';
import 'element-plus/es/components/checkbox-group/style/css';
export default {
  components: {
    // Lazy load Dialog component
    // Dialog: () => import('./components/ElementUI/Dialog/Dialog.vue'),
    Counter,
    ElForm,
    ElFormItem,
    ElMenu,
    ElMenuItem,
    ElOption,
    ElSelect,
    ElSubMenu,
    ElDialog,
    ImageCarousel,
    CategorySwiper,
    ElCollapse,
    ElCollapseItem,
    ElTabs,
    ElTabPane,
    ElIcon,
    ElCheckbox,
    ElCheckboxGroup,
    JobOfferForm,
    ContactForm,
    Swiper,
    SwiperSlide,
    Mosaic,
    Filters
  },
  setup() {
    const store = useStore();
    const {
      proxy
    } = getCurrentInstance();
    const width = window.innerWidth;
    const contextType = ref(store.state.context.active.slug);
    const contextTypeTabs = ref('walkway_systems_for_industry');
    const activeJobName = ['0', '1', '2', '3', '4', '5', '6'];
    const contextSelectSubmit = formId => {
      const form = document.querySelector('#' + formId);
      const body = document.querySelector('body');
      const data_main_page = document.querySelector('.context-switch__select-popper #' + contextType.value);
      if (body) {
        body.classList.add('context--' + contextType.value);
      }
      if (form) {
        form.action = data_main_page.dataset.typeMain;
        setTimeout(() => {
          form.submit();
        }, 10);
      }
    };
    const activeName = ref('');
    onMounted(() => {
      if (contextType.value === 'walkway_systems_for_industry') {
        activeName.value = 'tab-0';
        contextTypeTabs.value = 'walkway_systems_for_industry';
      } else if (contextType.value === 'construction_sites_protection') {
        activeName.value = 'tab-1';
        contextTypeTabs.value = 'construction_sites_protection';
      } else if (contextType.value === 'all_products') {
        activeName.value = 'tab-all_products';
        contextTypeTabs.value = 'walkway_systems_for_industry';
      }
      const returnButton = document.querySelector('.header__return');
      if (returnButton) {
        returnButton.addEventListener('click', () => {
          activeName.value = 'tab-all_products';
        });
      }
      const lang_element = document.querySelector('.lang-switch');
      lang_element.classList.remove('hidden');
      lang_element.classList.add('flex');
      const all_standard_desc = document.querySelectorAll('.standard-item__desc--desktop');
      all_standard_desc.forEach(e => {
        const content = e.innerHTML.trim();
        const startTable = content.startsWith('<table');
        const table = e.querySelector('table');

        /* if (startTable) {
          e.classList.add('desc-first-table');
        } */

        const parent = e.parentNode;
        const additional = parent.querySelector('.additional-elements__box');
        const button = parent.querySelector('.standard-item__btn--desktop');
        if (!additional && table === null) {
          // (!additional && startTable) || (!additional && table === null)
          button.style.display = 'none';
        }
      });
    });
    watch(activeName, newVal => {
      const tabsHeader = document.querySelector('.mega-menu-archive-mobile .el-tabs__header');
      if (newVal === 'tab-all_products') {
        if (tabsHeader) {
          tabsHeader.style.display = 'none';
        }
      } else {
        if (tabsHeader) {
          tabsHeader.style.display = '';
        }
      }
    });
    const handleClick = (tab, event) => {};
    const tabPosition = ref('top');

    // Dialogs
    const dialogTableVisible = ref(false);

    //Certificates
    const certificatePopup = ref(null);
    const certificateSrc = ref(null);
    const certificateClick = value => {
      dialogTableVisible.value = true;
      certificateSrc.value = value;
    };
    const fullCertificate = () => {
      if (certificatePopup.value) {
        certificatePopup.value.src = certificateSrc.value;
      }
    };
    // FirstImageProduct
    const changeFirstImageProduct = (term, url) => {
      const displayWindow = document.querySelector('#galleryFirstImage_' + term);
      displayWindow.src = url;
    };
    // Video
    const fullVideoPlayer = ref(null);
    const playVideo = () => {
      if (fullVideoPlayer.value) {
        fullVideoPlayer.value.play();
      }
    };
    const pauseVideo = () => {
      if (fullVideoPlayer.value) {
        fullVideoPlayer.value.pause();
      }
    };

    // Return
    const historyIsEmpty = ref(false);
    const returnClick = value => {
      historyIsEmpty.value = window.history.length === 1;
      if (historyIsEmpty.value) {
        window.location.href = value;
      } else {
        window.history.back();
      }
    };

    // ChangeMap

    const country = ref(0);
    const city = ref(0);
    const changeMap = () => {
      const el = document.querySelector('#iframe-map');
      el.innerHTML = store.state.branches_location[country.value].cities[city.value].iframe_map;
    };
    const changeCountry = index => {
      country.value = index;
    };
    const changeCity = index => {
      city.value = index;
      changeMap();
    };
    const ourBrandsSwiper = ref(null);
    const onSwiperBrands = swiper => {
      ourBrandsSwiper.value = swiper;
    };
    const changeSlide = index => {
      ourBrandsSwiper.value.slideTo(index);
    };
    const slideChange = data => {
      const elOld = document.querySelector('.our-brands-fullpage-static__buttons #button-swiper-' + data.previousIndex);
      const elNew = document.querySelector('.our-brands-fullpage-static__buttons #button-swiper-' + data.activeIndex);
      elOld.classList.remove('active');
      elNew.classList.add('active');
    };
    let isScrollingSwiper = false;
    let debounceTimer = null; // Timer do debouncingu
    let touchStartY = 0; // Zmienna przechowująca pozycję początkową dla touch

    // Funkcja obliczająca szerokość paska przewijania
    function getScrollbarWidth() {
      const scrollDiv = document.createElement('div');
      scrollDiv.style.overflow = 'scroll';
      scrollDiv.style.visibility = 'hidden';
      document.body.appendChild(scrollDiv);
      const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
      document.body.removeChild(scrollDiv);
      return scrollbarWidth;
    }

    // Funkcja do ukrycia scrolla i dodania paddingu
    function disableScroll() {
      const scrollbarWidth = getScrollbarWidth();
      document.body.style.paddingRight = `${scrollbarWidth}px`;
      document.body.style.overflow = 'hidden';
    }

    // Funkcja do przywrócenia scrolla
    function enableScroll() {
      document.body.style.overflow = 'auto';
      document.body.style.paddingRight = '0px'; // Przywracamy oryginalny padding
    }
    function isInView() {
      const section = document.querySelector('.our-brands-fullpage__content');
      const rect = section?.getBoundingClientRect();
      return rect?.top >= 0 && rect?.bottom <= window.innerHeight;
    }
    const productTabs = ref(null);
    onMounted(() => {
      if (isInView()) {
        isScrollingSwiper = true;

        //disableScroll()
      }
      proxy.$emitter.on('full_page_brands', data => {
        if (data.isIntersecting) {
          if (data.intersectionRatio > 0.50 && data.intersectionRatio < 0.55) {
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            });
          } else if (data.intersectionRatio > 0.99) {
            isScrollingSwiper = true;

            //disableScroll()
          } else {
            //enableScroll()
            isScrollingSwiper = false;
          }
        } else {
          if (data.intersectionRatio !== 0) {
            //enableScroll()
            isScrollingSwiper = false;
          }
        }
      });
      const elPosition = document.querySelector('.our-brands__content');
      const content = document.querySelector('.our-brands-fullpage__content');
      if (ourBrandsSwiper.value) {
        //ourBrandsSwiper.value.allowTouchMove = false
        //ourBrandsSwiper.value.allowClick = false
        //ourBrandsSwiper.value.allowSlideNext = false
        //ourBrandsSwiper.value.allowSlidePrev = false

        ourBrandsSwiper.value.on('touchEnd', (swiper, event) => {
          if (swiper.isEnd) {
            if (('ontouchstart' in window || window.matchMedia('(pointer: coarse)').matches) && swiper.touches.diff < 0) {
              isScrollingSwiper = false;
              window.scrollTo({
                top: elPosition.getBoundingClientRect().top + window.scrollY,
                behavior: 'smooth'
              });
            }
          }
        });
      }
      function handleScroll(event) {
        if (isScrollingSwiper) {
          if (debounceTimer) {
            event.preventDefault(); // Zatrzymanie scrolla podczas debouncingu
            return;
          }
          let delta = event.deltaY || event.touches && touchStartY - event.touches[0].clientY || 0;
          if (delta > 0) {
            // Scroll w dół
            if (!ourBrandsSwiper.value.isEnd) {
              ourBrandsSwiper.value.slideNext();
              event.preventDefault(); // Zatrzymanie scrolla
            } else {
              event.preventDefault(); // Zatrzymanie scrolla

              //enableScroll()
              isScrollingSwiper = false;
              window.scrollTo({
                top: elPosition.getBoundingClientRect().top + window.scrollY,
                behavior: 'smooth'
              });
            }
          } else {
            // Scroll w górę
            if (!ourBrandsSwiper.value.isBeginning) {
              ourBrandsSwiper.value.slidePrev();
              event.preventDefault(); // Zatrzymanie scrolla
            }
          }
          debounceTimer = setTimeout(() => {
            debounceTimer = null; // Reset timeru po upływie czasu
          }, 500); // 300ms przerwy pomiędzy przewinięciami traktowanymi jako jeden ruch

          event.preventDefault(); // Zatrzymanie domyślnego scrolla
        }
      }

      // Zdarzenie `touchstart` ustawiające początkową pozycję dotyku
      content?.addEventListener('touchstart', event => {
        touchStartY = event.touches[0].clientY;
      });

      // Obsługa zdarzeń dla scrolla kółkiem myszy
      content?.addEventListener('wheel', handleScroll);

      // Obsługa zdarzeń dla scrolla z touchpada (touchmove)
      content?.addEventListener('touchmove', handleScroll);

      // Obsługa zdarzeń scrolla dla laptopowych gestów
      content?.addEventListener('scroll', handleScroll);
      const urlParams = new URLSearchParams(window.location.search);
      const referrerUrl = document.referrer;
      if (referrerUrl) {
        const referrer = new URL(referrerUrl);
        const referrerUrlParams = new URLSearchParams(referrer.search);
        if (urlParams.has('filters') || referrerUrlParams.has('filters')) {
          productTabs.value = 'elements';
          setTimeout(() => {
            window.scrollTo({
              top: document.querySelector('#product-tabs-box').getBoundingClientRect().top,
              behavior: 'smooth'
            });
          }, 500);
        } else {
          productTabs.value = '0';
        }
      } else {
        if (urlParams.has('filters')) {
          productTabs.value = 'elements';
          setTimeout(() => {
            window.scrollTo({
              top: document.querySelector('#product-tabs-box').getBoundingClientRect().top,
              behavior: 'smooth'
            });
          }, 500);
        } else {
          productTabs.value = '0';
        }
      }
      const anchor = window.location.hash;
      if (anchor) {
        const targetElement = document.querySelector(anchor);
        if (targetElement) {
          const offset = 100; // Liczba pikseli, przed którą zatrzymujemy
          const elementPosition = targetElement.getBoundingClientRect().top + window.scrollY;
          const offsetPosition = elementPosition - offset;
          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
          });
        }
      }
    });
    const thumbsSwiper = ref(null);
    const setThumbsSwiper = swiper => {
      thumbsSwiper.value = swiper;
    };
    const moreContent = (el, gallery_id, desc_id) => {
      if (gallery_id != '0') {
        const test = document.querySelector('#' + gallery_id);
        test.classList.toggle('max-h-[0rem]');
        test.classList.toggle('max-h-[10000rem]');
        test.classList.toggle('pt-[2.4rem]');
        test.classList.toggle('pt-0');
      }
      if (desc_id) {
        const desc = document.querySelector('#' + desc_id);
        const table = desc.querySelectorAll('table');
        table.forEach(t => {
          t.classList.toggle('table');
        });
      }
      const btn = document.querySelector('.' + el);
      btn.classList.toggle('more');
      btn.querySelectorAll('span').forEach(item => {
        item.classList.toggle('hidden');
      });
    };
    const moreContent2 = (el, id) => {
      const test = document.querySelector('#' + id);
      test.classList.toggle('max-h-[0rem]');
      test.classList.toggle('max-h-[10000rem]');
      const btn = document.querySelector('.' + el);
      btn.classList.toggle('more');
      btn.querySelectorAll('span').forEach(item => {
        item.classList.toggle('hidden');
      });
    };
    return {
      width,
      contextType,
      contextSelectSubmit,
      dialogTableVisible,
      fullVideoPlayer,
      playVideo,
      pauseVideo,
      activeName,
      handleClick,
      tabPosition,
      fullCertificate,
      certificatePopup,
      certificateClick,
      changeCountry,
      changeCity,
      returnClick,
      historyIsEmpty,
      activeJobName,
      modules: [Navigation, Autoplay, Thumbs, Grid, Mousewheel],
      Mosaic,
      Filters,
      changeSlide,
      onSwiperBrands,
      slideChange,
      thumbsSwiper,
      setThumbsSwiper,
      moreContent,
      moreContent2,
      productTabs,
      contextTypeTabs,
      changeFirstImageProduct
    };
  }
};