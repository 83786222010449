import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "image-carousel__dialog p-0" }
const _hoisted_4 = { class: "dialog__swiper" }
const _hoisted_5 = { class: "dialog__swiper-main px-[2rem] md:px-0 pt-[9rem]" }
const _hoisted_6 = ["src", "alt"]
const _hoisted_7 = { class: "dialog__swiper-thumbs mt-[3rem]" }
const _hoisted_8 = ["src", "alt"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_swiper_slide = _resolveComponent("swiper-slide")
  const _component_swiper = _resolveComponent("swiper")
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_swiper, {
      ref: "swiperRef",
      modules: $setup.modules,
      direction: "horizontal",
      reverseDirection: true,
      loop: true,
      "slides-per-view": 5,
      "space-between": 32,
      breakpoints: $setup.breakpoints,
      navigation: {
      nextEl: '.custom-swiper-button-next',
      prevEl: '.custom-swiper-button-prev'
    },
      onSlideChange: $setup.onSlideChange
    }, {
      default: _withCtx(() => [
        ($props.slidertype == 'logos' )
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($props.items, (item, index) => {
              return (_openBlock(), _createBlock(_component_swiper_slide, {
                key: index,
                class: _normalizeClass(['image-carousel__item'])
              }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    src: item.image.url,
                    alt: item.image.alt || 'Carousel Image',
                    loading: "lazy",
                    width: "190px",
                    height: "50px",
                    class: "h-[4rem] object-contain object-left"
                  }, null, 8, _hoisted_1)
                ]),
                _: 2
              }, 1024))
            }), 128))
          : ($props.slidertype == 'images')
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList($props.items, (item, index2) => {
                return (_openBlock(), _createBlock(_component_swiper_slide, {
                  key: index2,
                  class: _normalizeClass('image-carousel__item image-carousel__item--full-images'),
                  onClick: $event => ($setup.openLightbox(index2))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      src: item.image.url,
                      alt: item.image.alt || 'Carousel Image',
                      loading: "lazy",
                      width: "190px",
                      height: "50px"
                    }, null, 8, _hoisted_2)
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              }), 128))
            : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modules", "breakpoints", "onSlideChange"]),
    _cache[2] || (_cache[2] = _createStaticVNode("<div class=\"swiper-navigation flex justify-center mt-[4.8rem] tablet:mt-[10rem] gap-[1.5rem]\"><div class=\"custom-swiper-button-prev transition w-[1.6rem] tablet:w-[1rem] h-[1.6rem] tablet:h-[1rem] cursor-pointer relative text-black\"><span class=\"tlc-icon-arrow -translate-y-2/4 -translate-x-2/4 absolute left-1/2 top-1/2 rotate-180\"></span></div><div class=\"custom-swiper-button-next transition w-[1.6rem] tablet:w-[1rem] h-[1.6rem] tablet:h-[1rem] cursor-pointer relative text-black\"><span class=\"tlc-icon-arrow absolute -translate-y-2/4 -translate-x-2/4 left-1/2 top-1/2\"></span></div></div>", 1)),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_el_dialog, {
        modelValue: $setup.lightboxVisible,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.lightboxVisible) = $event)),
        onOpen: $setup.onLightboxOpen,
        onClose: $setup.onLightboxClose
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_swiper, {
                onSwiper: $setup.onSwiper,
                direction: "horizontal",
                loop: true,
                navigation: {
              nextEl: '.custom-swiper-button-next',
              prevEl: '.custom-swiper-button-prev'
            },
                class: "dialog__swiper image-carousel__item image-carousel__item--centered-full",
                modules: $setup.modules,
                thumbs: { swiper: $setup.thumbsSwiper },
                initialSlide: $setup.activeIndex
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (item, index3) => {
                    return (_openBlock(), _createBlock(_component_swiper_slide, { key: index3 }, {
                      default: _withCtx(() => [
                        _createElementVNode("img", {
                          src: item.image.url,
                          alt: $setup.altText,
                          loading: "lazy",
                          width: "190px",
                          height: "50px",
                          class: "object-contain object-left"
                        }, null, 8, _hoisted_6)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }, 8, ["onSwiper", "modules", "thumbs", "initialSlide"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_swiper, {
                onSwiper: $setup.setThumbsSwiper,
                loop: true,
                spaceBetween: 16,
                slidesPerView: 4.2,
                watchSlidesProgress: true,
                freeMode: true,
                modules: $setup.modules,
                class: "dialog__swiper dialog__swiper--thumbs",
                breakpoints: {
              768: {
                slidesPerView: 8.2,
              },
              1024: {
                slidesPerView: 12.2
              }
            }
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (item, index4) => {
                    return (_openBlock(), _createBlock(_component_swiper_slide, {
                      key: index4,
                      class: _normalizeClass(['image-carousel__item'])
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("img", {
                          src: item.image.url,
                          alt: item.image.alt || 'Carousel Image',
                          loading: "lazy",
                          width: "190px",
                          height: "50px",
                          class: "h-[8rem] w-[8rem] md:h-[10rem] md:w-[10rem] object-cover"
                        }, null, 8, _hoisted_8)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }, 8, ["onSwiper", "modules"])
            ]),
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "swiper-navigation flex justify-center mt-[3rem] md:mt-[1.6rem] gap-[1.5rem]" }, [
              _createElementVNode("div", { class: "custom-swiper-button-prev transition w-[1rem] h-[1rem] flex text-[1rem] cursor-pointer relative text-white" }, [
                _createElementVNode("span", { class: "tlc-icon-arrow -translate-y-2/4 -translate-x-2/4 absolute left-1/2 top-1/2 rotate-180" })
              ]),
              _createElementVNode("div", { class: "custom-swiper-button-next transition w-[1rem] h-[1rem] flex text-[1rem] cursor-pointer relative text-white" }, [
                _createElementVNode("span", { class: "tlc-icon-arrow absolute -translate-y-2/4 -translate-x-2/4 left-1/2 top-1/2" })
              ])
            ], -1))
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "onOpen", "onClose"])
    ])
  ], 64))
}